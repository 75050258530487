import { Config, CognitoIdentityCredentials } from 'aws-sdk'
import { CognitoUser, CognitoUserPool, AuthenticationDetails, CognitoUserAttributem, CognitoRefreshToken } from 'amazon-cognito-identity-js'

import config from '@/config'

// We'll want to create a class for CognitoAuth which will
// contain all the methods we will need within our App
export default class CognitoAuth {
  // The constructor for this class will initialize our userSession
  // as null
  constructor () {
    this.userSession = null
    this.userSessionAttributes = null
    this.cognitoUser = null
    // this.accessToken = null
  }

  isAuthenticated (cb) {
    let cognitoUser = this.getCurrentUser()
    if (cognitoUser != null) {
        cognitoUser.getSession((err, session) => {
        if (err) {
            console.log(err)
            return cb(err, false)
        }

        if (Config.credentials.needsRefresh()) {
          
          const refreshTokenPromise = this.refreshTokenWrapper()
          refreshTokenPromise
          .then((successMsg) => {
          })
          .catch((errorMsg) => { 
              console.log(errorMsg);
          });
          
        }        
        return cb(session, true)
        })
    } else {
        cb(null, false)
    }
  }

  refreshTokenWrapper() {
    return new Promise((resolve, reject) => {
      this.refreshToken((result, err) => {
        if(err) {
          console.log("error.refreshTokenWrapper")
          console.log(result)
          reject((result, false))
          }        
          resolve((result,true)); 
        })
    });
  }

  refreshToken(cb){
    let cognitoUser = this.getCurrentUser()
    if (cognitoUser != null) {

      cognitoUser.getSession((err, session) => {
        if (err) {
            return cb(err, true)
        }    
        let cognitoUser = this.getCurrentUser();
        let refresh_token = session.getRefreshToken();

        cognitoUser.refreshSession(refresh_token, (err, session) => {
          if (err) {
            return cb(err, true)
          } else {

            var logins = {}
            logins['cognito-idp.' + config.region + '.amazonaws.com/' + config.UserPoolId] = session.getIdToken().getJwtToken()

            var token = session.getIdToken().getJwtToken();
            localStorage.setItem('token', token)  

            Config.credentials = new CognitoIdentityCredentials({
                IdentityPoolId: config.IdentityPoolId,
                Logins: logins
              },
              {
                region: config.region
              }
            )

            Config.credentials.refresh(err => {
              if (err) {
                return cb(err, true)
              } else {
                return cb('TOKEN SUCCESSFULLY UPDATED', false)
              }
            });        
          }
        });
      })      
    }
  }

  getUserAttributes(cb) {
    let cognitoUser = this.getCurrentUser()
    if (cognitoUser != null) {
      cognitoUser.getSession((err, session) => {
      if (err) {          
          return cb(err, false)
      }
      cognitoUser.getUserAttributes(function(err, result) {
        if (err) {
          return cb(err, false)
        }
        return cb(result, true)
      })    
     })  
    }
  }

  // this will set up our app to use cognito to use
  // the user pool that we'll be creating later on
  configure (config) {
    if (typeof config !== 'object' || Array.isArray(config)) {
        throw new Error('[CognitoAuth error] valid option object required')
    }
    this.userPool = new CognitoUserPool({
        UserPoolId: config.UserPoolId,
        ClientId: config.ClientId
    })
    Config.region = config.region
    Config.credentials = new CognitoIdentityCredentials({
        IdentityPoolId: config.IdentityPoolId
    })
    this.options = config
  }
  // a signup function which will allow new people
  // to create an account in our app
  signup(username, email, pass, cb) {
      let attributeList = [
          new CognitoUserAttribute({
              Name: 'email',
              Value: email
          })
      ]

      this.userPool.signUp(username, pass, attributeList, null, cb)
  }


  // a function that will allow existing users to
  // authenticate with our application
  authenticate (username, pass, cb) {

    let authenticationData = { Username: username, Password: pass }
    let authenticationDetails = new AuthenticationDetails(authenticationData)
    let userData = { Username: username, Pool: this.userPool }
    let cognitoUser = new CognitoUser(userData)
    this.cognitoUser = cognitoUser;

    cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
            var logins = {}
            logins['cognito-idp.' + config.region + '.amazonaws.com/' + config.UserPoolId ] = result.getIdToken().getJwtToken()
            
            Config.credentials = new CognitoIdentityCredentials({
                IdentityPoolId: config.IdentityPoolId,
                Logins: logins
              },
              {
                region: config.region
              }
            )
            localStorage.setItem('token', result.getIdToken().getJwtToken())            
            // axios.defaults.headers.common['Authorization'] = result.getIdToken().getJwtToken()

            //refreshes credentials using AWS.CognitoIdentity.getCredentialsForIdentity()
            Config.credentials.refresh(error => {
              if (error) {
                console.error(error);
              } else {
                // Instantiate aws sdk service objects now that the credentials have been updated.
                // example: var s3 = new AWS.S3();
                console.log('Successfully logged!');
              }
            });
            cb(null, result)
        }.bind(this),
        onFailure: function (err) {
            cb(err, true);
        },
        mfaRequired: function(codeDeliveryDetails) {
          // MFA is required to complete user authentication.
          // Get the code from user and call
          console.error("MFA not implemented")
          // cognitoUser.sendMFACode(mfaCode, this)
      },        
        newPasswordRequired: function (userAttributes, requiredAttributes) {
          // the api doesn't accept this field back
          delete userAttributes.email_verified;
          delete userAttributes.email;

          // store userAttributes in local Storage
          localStorage.setItem('userSessionAttributes', JSON.stringify(userAttributes))

          cb("New password is required", false)
        }
    })

  }

  handleNewPassword(newPassword, cb) {

    var userSessionAttributes = JSON.parse(localStorage.getItem('userSessionAttributes'));
    
    this.cognitoUser.completeNewPasswordChallenge(newPassword, userSessionAttributes, {
      onSuccess: (result) => {
        cb(false, "Password update successfully ")
      },
      onFailure: (err) => {
        cb(true, err)
      }
    });
  }


  forgotPassword(username, cb) {

    let userData = { Username: username, Pool: this.userPool }
    let cognitoUser = new CognitoUser(userData)

    cognitoUser.forgotPassword({
      onSuccess: function(data) {
        let msg = "Confirmation code send" // to "+ data.CodeDeliveryDetails.Destination
        cb(false, msg)        
      },
      onFailure: function(err) {
        console.log(err)
        let msg = "Confirmation code send"
        cb(true, msg)
      }

    });

  }

  changePassword(username, newPassword, verificationCode, cb) {

    if (verificationCode) {
      let userData = { Username: username, Pool: this.userPool }
      let cognitoUser = new CognitoUser(userData)

      cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess() {
          cb(false, "Password changed sucessfully")
        },
        onFailure(err) {          
          cb(true, err)
        },
      });
    }

  }


  // a helper function that allows us to
  // get the information for the current user
  getCurrentUser () {
    return this.userPool.getCurrentUser()
  }

  // a function that allows us to confirm newly
  // registered users of our app
 confirmRegistration (username, code, cb) {
    let cognitoUser = new CognitoUser({
        Username: username,
        Pool: this.userPool
    })
    cognitoUser.confirmRegistration(code, true, cb)
  }
  // does what it says on the tin, allows users
  // to logout if they are already logged in
  logout () {
    this.getCurrentUser().signOut()
  }

  // Retrieve the users current token if they have
  // a session, otherwise returns null
  getIdToken (cb) {
    if (this.getCurrentUser() == null) {
        return cb(null, null)
    }
    this.getCurrentUser().getSession((err, session) => {
        if (err) return cb(err)
        if (session.isValid()) {
        return cb(null, session.getIdToken().getJwtToken())
        }
        cb(Error('Session is invalid'))
    })
  }

}

// This installed CognitoAuth into our Vue instance
CognitoAuth.install = function (Vue, options) {
  Object.defineProperty(Vue.prototype, '$cognitoAuth', {
    get () { return this.$root._cognitoAuth }
  })

  Vue.mixin({
    beforeCreate () {
      if (this.$options.cognitoAuth) {
        this._cognitoAuth = this.$options.cognitoAuth
        this._cognitoAuth.configure(options)
      }
    }
  })
}


// AWS.config.credentials = new AWS.CognitoIdentityCredentials(
//   {},
//   {   region: 'us-east-1' });