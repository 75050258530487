import axiosInstance from "@/services/api";
import cognitoAuth from '@/cognito'
 
axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token');
    if (token == null) {
        return config;
    }

    config.headers.common["Authorization"] = `${token}`;
    return config;
});


axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalConfig = error.config;

        if (originalConfig.url !== "/login" && error.response) {
            if (error.response.status === 401 && !originalConfig._retry) {
                console.log(error.response.status)
                originalConfig._retry = true;
                
                 await cognitoAuth.refreshTokenWrapper()
                
                const token = localStorage.getItem('token');
    
                originalConfig.headers["Authorization" ] = `${token}`;
                
                return axiosInstance(originalConfig);
            }
        return Promise.reject(error);
          
        } else {
            return Promise.reject(error);
        }
    }
    );

export default axiosInstance;